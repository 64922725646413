

.register-form  {
  max-width: 320px;
}

.register-form .form_item {
  margin-bottom: 12px;
}

.register-form-button {
  width: 100%;
}
.register-form-button #captchaimg{
    height: 32px;
}
.register_title {
   font-weight: bold;
   color: rgb(51, 51, 51);
   font-size: 28px;
   margin-left: 10px;
}
#phone_register {
  margin-left: 10vh;
}

#forgot_pwd {
  margin-left: 12em;
}
.register_brand {
  width: 50px;
  margin-left: 10px;
}
.register_tip {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
}
