

.resetPwd-form  {
  max-width: 320px;
}

.resetPwd-form .form_item {
  margin-bottom: 12px;
}

.resetPwd-form-button {
  width: 100%;
}
.resetPwd-form-button #captchaimg{
    height: 32px;
}
.resetPwd_title {
   font-weight: bold;
   color: rgb(51, 51, 51);
   font-size: 28px;
   margin-left: 10px;
}
#phone_resetPwd {
  margin-left: 10vh;
}

.resetPwd_brand {
  width: 50px;
  margin-left: 10px;
}
.resetPwd_tip {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
}

.currAccountLogin {
  line-height: 7vh;
  font-size: 16px;
}