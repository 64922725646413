body {
  background-image: url(../../static/images/u10997.png);
  background-size: 100% 100%;
  position: relative;
  min-height: 100vh;
}

.login-form {
  /* max-width: 320px; */
}
.login-form .jz{
  margin-top:30px;
}
.login-form .jz .ant-col .ant-form-item-children {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.jz .ant-form-item-control {
  line-height: 1.2;
}

.login-form .form_item {
  margin-bottom: 20px;
}

.login-form-button {
  width: 100%;
}

.login-form-button,
#captchaimg {
  height: 32px;
}

.sys_title {
  font-weight: bold;
  color: gray;
}

#phone_login {
  margin-left: 10vh;
}
.ant-input-affix-wrapper .ant-input{
  height: 42px;
}

.ant-col .login-form {
  margin-top: 28px;
}
.ant-col .ant-btn{
  height: 42px;
}
.tabs_switch {

  width: 840px;
  height: 540px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  /* padding: 0 40px; */
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.2);
}

.tabs {
  width: 50%;
  padding: 0 3vw;

  overflow: hidden;
}

.tabs-left {
  width: 50%;
}

.tabs-title {
  font-size: 24px;
  font-weight: 700;
  text-align: justify;
  color: #303133;

}

.tab-img {
  width: 100%;
  height: 100%;
}

.brand-picture {
  width: 36px;
}

.tabs-header {
  padding: 2.5vw 0;
  padding-bottom: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs-header .tabs-btns {
  display: flex;
  align-items: center;
}

.tabs-btns .t-btn {
  box-sizing: border-box;
  width: 93px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #606266;
  cursor: pointer;
}

.tabs-btns .btn-left {
  border-right: none;
  border-radius: 4px 0px 0px 4px;
}

.tabs-btns .btn-right {
  border-radius: 0px 4px 4px 0px;
}

.tabs-btns .active {
  background-color: #1890ff;
  color: #fff;
}

.brand-title {
  font-size: 22px;
  font-weight: 700;
  text-align: justify;
  color: #606266;
  margin-left: 8px;
}

.top-row {
  padding: 0 48px;
  box-sizing: border-box;
  padding-top: 30px;
}

.top-row .t-row-left {
  display: flex;
  align-items: center;
}

#captchaimg {
  padding-bottom: 4px;
}